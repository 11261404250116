import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { capitalize } from 'lodash'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import CustomListGroup from '../../components/shared/CustomListGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSpinner, faUserCircle, faCloudArrowUp, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import LoadingButton from '../../components/shared/LoadingButton'
import EffModal from '../../components/shared/EffModal'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import UploadCsvModal from "../../components/modal/UploadCsvModal"
import ImportOptionsModal from "../../components/modal/ImportOptionsModal"
import ModalApiStatus from "../../components/modal/ModalApiStatus"

export default function Customers() {
    const { t, i18n } = useTranslation('Customers', 'common', 'Error')

    const initModalData = {
        "customer_id": "",
        "email": "",
        "phone": "",
        "line_user_id": "",
        "selected_tag": [],
        "colIsValid": true,
        "csvIsValid": true,
        "sizeIsValid": true,
        "invalidData": {
            "email_not_exist": false,
            "phone_not_exist": false,
            "tags_not_exist": false,
        }
    }
    const initInputCheck = {
        "email": {
            "required": true,
            "valid": true
        },
        "phone": {
            "required": false,
            "valid": true
        },
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    };
    const initModalShow = false;
    const initModal = {
        "inputCheck": initInputCheck,
        "data": initModalData,
        "tagList": [],
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow,
        "warningModalShow": false
    }

    const initTableListData = {
        "counts": 0,
        "data": false
    }
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }

    const [tableListData, setTableListData] = useState(initTableListData);
    const [modal, setModal] = useState(initModal);
    const [csvFile, setCsvFile] = useState('')
    const [csvName, setCsvName] = useState('')
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const onModalChange = (event) => {
        if (event.target.name === "email") {
            const mailReg = /^[a-zA-Z0-9._]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
            if (event.target.value === "" || event.target.value.match(mailReg)) {
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": {
                        ...modal.inputCheck,
                        "email": {
                            "required": true,
                            "valid": true
                        },
                    },
                    "data": {
                        ...modal.data,
                        "email": event.target.value
                    }
                }));
            } else {
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": {
                        ...modal.inputCheck,
                        "email": {
                            "required": true,
                            "valid": false
                        },
                    },
                    "data": {
                        ...modal.data,
                        "email": event.target.value
                    }
                }));
            }


        } else if (event.target.name === "phone") {
            // phone number check
            const phoneReg = /^(09)[0-9]{8}$/;
            if (event.target.value === "" || event.target.value.match(phoneReg)) {
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": {
                        ...modal.inputCheck,
                        "phone": {
                            "required": false,
                            "valid": true
                        },
                    },
                    "data": {
                        ...modal.data,
                        "phone": event.target.value
                    }
                }));

            } else {
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": {
                        ...modal.inputCheck,
                        "phone": {
                            "required": false,
                            "valid": false
                        },
                    },
                    "data": {
                        ...modal.data,
                        "phone": event.target.value
                    }
                }));
            }

        } else if (event.target.getAttribute('name') === "tag-list") {
            const selected_tag_data = modal.tagList.find(element => element.tag_id.toString() === event.target.getAttribute('value'))
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...modal.data,
                    "selected_tag": [...modal.data.selected_tag, selected_tag_data]
                }
            }));
        } else if (event.target.getAttribute('name') === "selected-tag") {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...modal.data,
                    "selected_tag": modal.data.selected_tag.filter(element => element.tag_id.toString() !== event.target.getAttribute('value'))
                }
            }));
        }
    }
    const handleModalClose = () => {
        setErrors(initErrors)
        setModal(prevState => ({
            ...prevState,
            "show": initModalShow,
            "warningModalShow": false,
            "uploadCsvModalShow": false,
            "checkCsvModalShow": false,
            "optionsModalShow": false,
        }))
        // setCustomerEfficiencyModal(prevState => ({
        //     ...prevState,
        //     "show": initModalShow
        // }))
        setApiStatus(initApiStatus)
        setCustomerEfficiencyModal(initCustomerEfficiencyModal)
        
    }
    const handleModalShow = (event) => {
        let customer_data = undefined
        let customer_tag = undefined
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": initInputCheck,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": !initModalShow,
                    "warningModalShow": false,
                    "loaded": true
                }));
                setErrors(initErrors);
                break;

            case "edit":
                customer_data = tableListData.data.find(element => element.customer_id === (event.target.value))
                customer_tag = modal.tagList.filter(element => customer_data.tag_id.includes(element.tag_id))
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": initInputCheck,
                    "data": {
                        ...modal.data,
                        "customer_id": customer_data.customer_id,
                        "email": customer_data.email,
                        "phone": customer_data.phone,
                        "selected_tag": customer_tag
                    },
                    "action": {
                        "type": "edit",
                        "targetId": event.target.value
                    },
                    "show": !initModalShow,
                    "warningModalShow": false,
                    "loaded": true
                }));
                break;

            case "delete":
                customer_data = tableListData.data.find(element => element.customer_id.includes(event.target.value))
                customer_tag = modal.tagList.filter(element => customer_data.tag_id.includes(element.tag_id))
                setModal(prevState => ({
                    ...prevState,
                    "inputCheck": initInputCheck,
                    "data": {
                        ...initModalData,
                        "customer_id": customer_data.customer_id,
                    },
                    "action": {
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    "show": initModalShow,
                    "warningModalShow": true,
                    "loaded": true
                }));
                break;

            case "unblock":
                // removed from blacklist
                let blacklist_data = tableListData.data.find(element => element.id && element.id.includes(event.target.value))
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initModalData,
                        "id": blacklist_data.id,
                        "from": blacklist_data.from,
                    },
                    "action": {
                        "type": "unblock",
                        "targetId": event.target.value
                    },
                    "show": initModalShow,
                    "warningModalShow": true,
                    "loaded": true
                }));
                break;

            case "efficiency":
                getCustomerEfficiencyModalData(event.target.value)
                setCustomerEfficiencyModal(prevState => ({
                    ...prevState,
                    "show": !initCustomerEfficiencyModalShow
                }))
                break;

            case "update-efficiency":
                updateCustomerEfficiency();
                break;

            case "upload-csv":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "upload-csv" // edit type here to upload customer or blacklist
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": true,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false,
                    "uploadCsvModalShow": false,
                }));
                break;
            case "customer":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "upload-csv-customer" // edit type here to upload customer or blacklist
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false,
                    "uploadCsvModalShow": true,
                }));
                break;
            case "blacklist":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "upload-csv-blacklist" // edit type here to upload customer or blacklist
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false,
                    "uploadCsvModalShow": true,
                }));
                break;
            default:
                break;
        }
    }
    
    const initErrors = {
        "required": {},
    }

    const [errors, setErrors] = useState(initErrors)

    if (errors.required.email !== undefined && modal.data.email !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "email": undefined,
            }
        }))
    }

    const handleModalSubmit = (event) => {
        let requiredErrors = {}
        if (Object.values(modal.inputCheck).filter(ele => ele.valid === true).length === Object.keys(modal.inputCheck).length) {
            switch (event.target.name) {
                case "create":
                    setErrors(initErrors)
                    if (modal.inputCheck.email.required === true && modal.data.email === "") {
                        requiredErrors.email = "必填"
                    }
                    setErrors(prevState => ({
                        ...prevState,
                        "required": requiredErrors,
                    }))
                    if (Object.keys(requiredErrors).length === 0) {
                        setModal(prevState => ({
                            ...prevState,
                            "show": false,
                            "warningModalShow": true
                        }))
                    }

                    break

                case "edit":
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "warningModalShow": true
                    }))

                    break

                case "upload-csv":
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "uploadCsvModalShow": false,
                        "warningModalShow": true,
                    }))

                    break

                case "upload-csv-customer":
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "uploadCsvModalShow": false,
                        "warningModalShow": true,
                    }))

                    break
                case "upload-csv-blacklist":
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "uploadCsvModalShow": false,
                        "warningModalShow": true,
                    }))

                    break

                default:
                    break
            }
        } else {
            //
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false
                }))
                break

            case "upload-csv":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "uploadCsvModalShow": true,
                }))
                break

            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false
                }))
                break
        }
    }

    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                postCustomerData()
                break

            case "edit":
                putCustomerData(event.target.value)
                break

            case "delete":
                deleteCustomerData(event.target.value)
                break

            case "unblock":
                deleteBlackListCustomerData(modal.data.from, modal.data.id)
                break

            case "upload-csv":
                importCustomerCsv()
                // TODO:FIXME:TODO:FIXME:TODO:FIXME:
                break
            case "upload-csv-customer":
                importCustomerCsv('customer')
                // TODO:FIXME:TODO:FIXME:TODO:FIXME:
                break
            case "upload-csv-blacklist":
                importCsv('blacklist')
                // TODO:FIXME:TODO:FIXME:TODO:FIXME:
                break
            default:
                //pass
                break
        }
    }
    async function getCustomerData() {
        const blacklistData = await apiAuthInstance({
            "url": "contact/blacklist/",
            "method": "get"
        })
        const customerData = await apiAuthInstance({
            "url": "contact/customer/directory/all/",
            "method": "get"
        })
        setTableListData({
            count: customerData.data.count + blacklistData.data.count,
            data: customerData.data.data.map((d) => ({ ...d, blocked: false })).concat(blacklistData.data.data.map((d) => ({ ...d, blocked: true, tag_id: [], tag_name: [] })))
        })
    }

    async function postCustomerData() {

        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/customer/",
            "method": "post",
            "data": {
                "email": modal.data.email,
                "phone": modal.data.phone,
                "line_user_id": modal.data.line_user_id,
                // "tag_id": modal.data.selected_tag.map(element => element.tag_id),
                "tag": modal.data.selected_tag.map(element => element.tag_id),
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCustomerData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function putCustomerData(targetId) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/customer/" + targetId + "/",
            "method": "put",
            "data": {
                "customer_id": modal.data.customer_id,
                "email": modal.data.email,
                "phone": modal.data.phone,
                "line_user_id": modal.data.line_user_id,
                // "tag_id": modal.data.selected_tag.map(element => element.tag_id),
                "tag": modal.data.selected_tag.map(element => element.tag_id),
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCustomerData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function deleteCustomerData(customer_id) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/customer/" + customer_id + "/",
            "method": "delete",
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCustomerData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function deleteBlackListCustomerData(from, id) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/blacklist/",
            "method": "delete",
            "params": {
                "from": from,
                "id": id
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCustomerData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function updateCustomerEfficiency() {
        await apiAuthInstance({
            "url": "contact/customer/efficiency/all/",
            "method": "post",
        }).then((response) => {
            console.log(response.data)
        })
    }
    
    async function importCustomerCsv() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "contact/customer/import/csv/",
            "method": "post",
            "data": {
                "csv_file": csvFile,
                "csv_name": csvName,
            }
        }).then((response) => {
            if (!response || !response.data) {
                console.log(response)
                return
            }
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...modal.data,
                    "sizeIsValid": response.data.size_is_valid,
                    "colIsValid": response.data.col_is_valid,
                    "csvIsValid": response.data.csv_is_valid,
                    "invalidData": response.data.data,
                    "invalidCount": response.data.count,
                },
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "uploadCsvModalShow": false,
                "checkCsvModalShow": true,
            }))
            // getCustomerData()
        })
    }

    async function importCsv(type) {
        const paths = {
            customer: "contact/customer/import/csv/",
            blacklist: "contact/blacklist/"
        }
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": paths[type],
            "method": "post",
            "data": {
                "csv_file": csvFile,
                "csv_name": csvName,
            }
        }).then((response) => {
            if (!response || !response.data) {
                console.log(response)
                return
            }
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...modal.data,
                    "colIsValid": response.data.col_is_valid,
                    "csvIsValid": response.data.csv_is_valid,
                    "invalidData": response.data.data,
                    "invalidCount": response.data.count,
                },
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "uploadCsvModalShow": false,
                "checkCsvModalShow": true,
            }))
        })
    }
    
    const initSmsPieData = []
    const [smsPieData, setSmsPieData] = useState(initSmsPieData)
    const initMailPieDataOpens = []
    const [mailPieDataOpens, setMailPieDataOpens] = useState(initMailPieDataOpens)
    const initMailPieDataClick = []
    const [setMailPieDataClicks] = useState(initMailPieDataClick)

    const initCustomerEfficiencyModalShow = false
    const initCustomerEfficiencyModal = {
        "data": {
            "phone": "-",
            "email": "-",
            "tag_name": "-",
            "customer_efficiency": {},
            "customer_efficiency_sms": [{ "campaign_name": "N/A", "status": "N/A", "received_time": "N/A", "cost": "N/A" }],
            "customer_efficiency_mail_sent": [{ "campaign_name": "N/A", "status": "N/A", "received_time": "N/A", }],
            "customer_efficiency_mail_open": [{ "campaign_name": "N/A", "status": "N/A", "received_time": "N/A", }],
            "customer_efficiency_mail_click": [{ "campaign_name": "N/A", "status": "N/A", "received_time": "N/A", }],
            "customer_efficiency_mail_bounce": [{ "campaign_name": "N/A", "status": "N/A", "received_time": "N/A", }],
        },
        "show": initCustomerEfficiencyModalShow,
    }
    const [customerEfficiencyModal, setCustomerEfficiencyModal] = useState(initCustomerEfficiencyModal)

    async function getCustomerEfficiencyModalData(customer_id) {
        const data = tableListData.data.find((element) => element.customer_id === customer_id)
        await apiAuthInstance({
            "url": "contact/customer/efficiency/" + customer_id + "/",
            "method": "get",

        }).then((response) => {
            console.log(response)
            setCustomerEfficiencyModal(prevState => ({
                ...prevState,
                "data": { ...response.data, tag_name: data.tag_name.join() },
                "show": !initCustomerEfficiencyModalShow
            }))
            const smsData = [
                { item: 'success_rate', count: response.data.customer_efficiency.sms_success_rate },
                { item: 'failed_rate', count: response.data.customer_efficiency.sms_failed_rate },
            ]
            const mailDataOpens = [
                { item: 'avg_open_rate', count: response.data.customer_efficiency.mail_open_rate },
                { item: 'avg_unopen_rate', count: (100 - response.data.customer_efficiency.mail_open_rate) },
            ]
            const mailPieDataClicks = [
                { item: 'avg_click_rate', count: response.data.customer_efficiency.mail_click_rate },
                { item: 'avg_unclick_rate', count: (100 - response.data.customer_efficiency.mail_click_rate) },
            ]
            setSmsPieData(smsData)
            setMailPieDataOpens(mailDataOpens)
            setMailPieDataClicks(mailPieDataClicks)
        })
    }

    useEffect(() => {
        async function getCustomerData() {
            const blacklistData = await apiAuthInstance({
                "url": "contact/blacklist/",
                "method": "get"
            })
            const customerData = await apiAuthInstance({
                "url": "contact/customer/directory/all/",
                "method": "get"
            })
            setTableListData({
                count: customerData.data.count + blacklistData.data.count,
                data: customerData.data.data.map((d) => ({ ...d, blocked: false })).concat(blacklistData.data.data.map((d) => ({ ...d, blocked: true, tag_id: [], tag_name: [] })))
            })
        }
        function getTagData() {
            apiAuthInstance({
                "url": "contact/tag-overview/",
                "method": "get",
                "params": {
                    "data_format": "simplify",
                    "tag_src": "tagview",
                }
            }).then((response) => {
                setModal(prevState => ({
                    ...prevState,
                    "tagList": response.data.data
                }))
            })
        }
        getCustomerData()
        getTagData()
    }, [])

    const [filters, setFilters] = useState({
        blocked: true,
        unblocked: true,
    })
    const handleFiltersChange = (e) => {
        setFilters({ ...filters, [e.target.name]: !filters[e.target.name] })
    }

    const [search, setSearch] = useState('')


    return (
        <>
        <div className="h-100 d-flex flex-column">
            {tableListData.data && 
                <Row xs="auto" className="mb-2 d-flex">
                    <Col xs={3}>
                        <Button variant="orcaLight" name="create" onClick={handleModalShow} title="新增">
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                style={{ pointerEvents: 'none' }}
                                className="pe-2"
                            />
                                {t('create_customer')}
                        </Button>
                        <Button className="ms-2" variant="orcaLight" name="upload-csv" onClick={handleModalShow} title="上傳">
                            <FontAwesomeIcon
                                icon={faCloudArrowUp}
                                style={{ pointerEvents: 'none' }}
                                className="pe-2"
                            />
                                {t('import_csv_file')}
                        </Button>
                    </Col>

                    <Col xs={4} className="d-flex my-auto ms-auto ps-0 text-orca">
                        <div className="d-flex px-3 ms-auto">
                            <Form.Check name="unblocked" checked={filters.unblocked} onChange={handleFiltersChange} />
                            &ensp;{t('unblocked')}
                        </div>
                        <div className="d-flex px-3">
                            <Form.Check name="blocked" checked={filters.blocked} onChange={handleFiltersChange} />
                            &ensp;{t('blocked')}
                        </div>
                    </Col>

                    <Col xs={5} className="d-flex">
                        <div className="d-flex ms-auto">
                            <Form.Control className="w-100" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common' })} />
                        </div>
                        <div className="mx-auto">
                            <Button
                                variant="orcaLight"
                                name="update-efficiency"
                                onClick={handleModalShow}
                                title="即時更新成效"
                            >
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    style={{ pointerEvents: 'none' }}
                                    className="pe-2"
                                />
                                    Update Efficiency
                            </Button>
                        </div>
                    </Col>
                </Row>
            }

            <UploadCsvModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "uploadCsvModalShow" : modal.uploadCsvModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleModalSubmit,
                "handleModalClose" : handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            setCsvFile={setCsvFile}
            setCsvName={setCsvName}
            sampleType={modal.action.type === 'upload-csv-customer' ? 'Customers' : 'Blacklists'}
            />

            <Modal show={modal.checkCsvModalShow} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('csv_import_result')}</Modal.Title>
                </Modal.Header>
                {!modal.data.sizeIsValid &&
                    <Modal.Body>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '4rem' }} icon={faCircleExclamation} />
                            </Col>
                        </Row>
                        <h5 className="text-center text-orca py-2 lh-lg">
                            {t('csv_import_error_limit_per_importation', { limit_per_importation: modal.data.invalidData.csv_max_length })}
                            {/* 資料過多，單次匯入上限{modal.data.invalidData.csv_max_length}筆。 */}
                        </h5>
                        <span className="text-center text-orca">
                            <Row className="d-flex justify-content-center h6">
                                <Col xs={5} className="p-0">
                                    {t('csv_import_error_limit_exceeded', { invalid_csv_length: modal.data.invalidData.csv_length })}
                                    {/* 該.csv有 {modal.data.invalidData.csv_length} 筆資料，請分割檔案之。 */}
                                </Col>
                            </Row>
                        </span>
                    </Modal.Body>
                }
                {!modal.data.colIsValid &&
                    <Modal.Body>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '4rem' }} icon={faCircleExclamation} />
                            </Col>
                        </Row>
                        <h5 className="text-center text-orca py-2 lh-lg">
                            {/* 以下資料欄位有誤，請修正後再次匯入： */}
                            {t('csv_import_error_col_title')}
                        </h5>
                        <span className="text-center text-orca">
                            {modal.data.invalidData.email_not_exist && 
                                <Row className="d-flex justify-content-center h6">
                                    <Col xs={5} className="text-end">
                                        {t('csv_import_error_col_not_found')}
                                    </Col>
                                    <Col xs={1} className="p-0">
                                        {t('email', { ns: 'common' })}
                                    </Col>
                                    <Col xs={6} className="text-start">
                                        {t('csv_import_error_col_name')}
                                    </Col>
                                </Row>
                            }
                            {modal.data.invalidData.phone_not_exist && 
                                <Row className="d-flex justify-content-center h6">
                                    <Col xs={5} className="text-end">
                                        {t('csv_import_error_col_not_found')}
                                    </Col>
                                    <Col xs={1} className="p-0">
                                        {t('phone', { ns: 'common' })}
                                    </Col>
                                    <Col xs={6} className="text-start">
                                        {t('csv_import_error_col_name')}
                                    </Col>
                                </Row>
                            }
                            {modal.data.invalidData.tags_not_exist &&  
                                <Row className="d-flex justify-content-center h6">
                                    <Col xs={5} className="text-end">
                                        {t('csv_import_error_col_not_found')}
                                    </Col>
                                    <Col xs={1} className="p-0">
                                        {t('tags', { ns: 'common' })}
                                    </Col>
                                    <Col xs={6} className="text-start">
                                        {t('csv_import_error_col_name')}
                                    </Col>
                                </Row>
                            }
                        </span>
                    </Modal.Body>
                }
                {modal.data.colIsValid && (<>{modal.data.csvIsValid ? 
                    <Modal.Body>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '5rem' }} icon={faCircleCheck} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5 className="text-center text-orcaLight">{t('csv_import_success_title')}</h5>
                                <h6 className="text-center text-orca pt-2 pb-4 lh-lg">
                                    {t('csv_import_success_content_1')}<br/>
                                    {t('csv_import_success_content_2')}<br/>
                                    {t('csv_import_success_content_3')}
                                </h6>
                            </Col>
                        </Row>
                    </Modal.Body> :
                    <Modal.Body className="px-5 py-4">
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '5rem' }} icon={faCircleExclamation} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center text-orca pt-1 pb-3 lh-lg">
                                <h3>{t('oops', { ns: 'common' })}</h3>
                                <h5>
                                    {t('csv_import_error_data_title')}
                                    {/* 以下資料內容有誤，請修正後再次匯入： */}
                                </h5>
                            </Col>
                        </Row>

                        <div className="scroller" style={{ maxHeight : '28vh', overflowY: 'auto', }}>
                            <table className="importCheckTable bg-orcaWhite rounded-lg lh-lg text-orca">
                                <thead>
                                    <tr>
                                        <th>{t('csv_import_error_data_col')}</th>
                                        <th>{t('csv_import_error_data_content')}</th>
                                        <th>{t('csv_import_error_data_reason')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modal.data.invalidData && modal.data.invalidData.map((element, index) => (
                                        <React.Fragment key={index}>
                                            {element.email_empty_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td title={element.email}>{element.email}</td>
                                                    <td>{t('csv_import_error_data_email_col_no_data')}</td>
                                                </tr> : <></>
                                            }
                                            {element.email_format_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td title={element.email}>{element.email}</td>
                                                    <td>{t('csv_import_error_data_email_format')}</td>
                                                </tr> : <></>
                                            }
                                            {element.phone_format_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td title={element.email}>{element.phone}</td>
                                                    <td>{t('csv_import_error_data_phone_format')}</td>
                                                </tr> : <></>
                                            }
                                            {element.email_duplicated_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td title={element.email}>{element.email}</td>
                                                    <td>{t('csv_import_error_data_email_exist')}</td>
                                                </tr> : <></>
                                            }
                                            {element.phone_duplicated_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td title={element.email}>{element.phone}</td>
                                                    <td>{t('csv_import_error_data_phone_exist')}</td>
                                                </tr> : <></>
                                            }
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    }</>)
                }

                <Modal.Footer className="justify-content-center">
                    <Button variant="orca" onClick={handleModalClose}>
                        {t('confirm', { ns:  'common' })}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_customer')}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_customer')}</Modal.Title> }
                    {/* <Modal.Title>{capitalize(modal.action.type)} a Customer</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label className="h6 text-orca d-flex">
                                        <Col xs={6}>
                                            {t('email_2', { ns: 'common' })}
                                        </Col>
                                        {
                                            modal.inputCheck.email.valid === false
                                                ?
                                                <Col className="text-red text-end p-0 m-0">
                                                    {t('format_error', { ns: 'Error' })}
                                                </Col>
                                                :
                                                <></>
                                        }
                                    </Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        defaultValue={modal.data.email}
                                        onChange={onModalChange}
                                        style={modal.inputCheck.email.valid === false ? { border: '1px solid red' } : 
                                            errors.required.email !== undefined ? { border: '2px solid crimson' } : {}}
                                    />
                                    <Form.Text className="text-red">{errors.required.email}</Form.Text>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicPhone">
                                    <Form.Label className="h6 text-orca d-flex">
                                        <Col xs={6}>
                                            {t('phone_2', { ns: 'common'})}
                                        </Col>
                                        {
                                            modal.inputCheck.phone.valid === false
                                                ?
                                                <Col className="text-red text-end">
                                                    {t('example', { ns: 'common'})}：0905123456
                                                </Col>
                                                :
                                                <></>
                                        }
                                    </Form.Label>
                                    <Form.Control
                                        name="phone"
                                        type="text"
                                        placeholder="Phone Number"
                                        maxLength={10}
                                        defaultValue={modal.data.phone}
                                        onChange={onModalChange}
                                        style={modal.inputCheck.phone.valid === false ? { border: '1px solid red' } : {}}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label className="h6 text-orca d-flex">
                                        <Col xs={6}>
                                            Line User ID
                                        </Col>
                                    </Form.Label>
                                    <Form.Control
                                        name="line_user_id"
                                        type="text"
                                        placeholder="Line User ID"
                                        defaultValue={modal.data.line_user_id}
                                        onChange={(event) => setModal({
                                            ...modal,
                                            data: {
                                                ...modal.data,
                                                line_user_id: event.target.value,
                                            },
                                        })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Row style={{ height: '40vh' }}>
                            <Col xs={6} className="h-100">
                                <Form.Group className="mb-3 h-100" controlId="formBasicPhone">
                                    <Form.Label className="h6 mb-2 text-orca">{t('tag_list')}</Form.Label>
                                    <React.Fragment>
                                        <Row className="w-100 h-90 mb-2 mx-0 px-0 listSelector" style={{ overflowY: 'auto' }}>
                                            <CustomListGroup settings={{
                                                listGroupConfig: [
                                                    {
                                                        "attributeName": "tag-list",
                                                        "fieldAsValue": "tag_id",
                                                        "fieldAsListGroupContent": "tag_name",
                                                        "fieldAsBadgeContent": "counts",
                                                        "onClick": onModalChange
                                                    },
                                                ],
                                                "data": modal.tagList.filter(t => modal.data.selected_tag.find(s => s.tag_id === t.tag_id) === undefined),
                                            }}
                                            />
                                        </Row>
                                    </React.Fragment>
                                </Form.Group>
                            </Col>
                            <Col xs={6} className="h-100">
                                <Form.Group className="mb-3 h-100" controlId="formBasicPhone">
                                    <Form.Label className="h6 mb-2 text-orca">{t('selected_tag_list')}</Form.Label>
                                    <Row className="mb-2 mx-0 px-0 h-90 listSelector" style={{ overflowY: 'auto' }}>
                                        <CustomListGroup settings={{
                                            listGroupConfig: [
                                                {
                                                    "attributeName": "selected-tag",
                                                    "fieldAsValue": "tag_id",
                                                    "fieldAsListGroupContent": "tag_name",
                                                    "fieldAsBadgeContent": "counts",
                                                    "onClick": onModalChange
                                                },
                                            ],
                                            "data": modal.data.selected_tag,
                                        }}
                                        />
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "email",
                        "dataType": "text",
                        "fieldName": "email"
                    },
                    {
                        "head": "phone",
                        "dataType": "text-center",
                        "fieldName": "phone"
                    },
                    // {
                    //     "head": "Line User ID",
                    //     "dataType": "text-center",
                    //     "fieldName": "line_user_id"
                    // },
                    {
                        "head": "selected_tags",
                        "dataType": "list",
                        "fieldName": "tag_name",
                    },
                    {
                        "head": "functions",
                        "dataType": "update and efficiency and delete and ban",
                        "fieldAsValue": "customer_id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    }
                ],
                "data": tableListData.data && tableListData.data.filter((d) => ((d.email && d.email.includes(search)) || (d.phone && d.phone.includes(search))) && ((filters.blocked && d.blocked) || (filters.unblocked && !d.blocked))),
                "size": 10,
                "pagination": true,
                "loading": !tableListData.data,
            }}
            />

            <Modal size="xl" show={customerEfficiencyModal.show}>
                <Modal.Header className="bg-light" closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('customer_report_title', { email: customerEfficiencyModal.data.email })}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 bg-light">
                    <Row className="h-92">
                        <Col xs={4} className="h-92">
                            <div className="d-flex m-auto text-orcaLighter py-5">
                                <FontAwesomeIcon
                                    style={{ fontSize : '10rem'}}
                                    className="m-auto"
                                    icon={faUserCircle}
                                />
                            </div>
                            <div className="px-4 mt-auto">
                                <h6 className="pt-3 text-orca mb-0">
                                    {t('email_2', { ns: 'common'})}
                                </h6>
                                <h4 className="oneLineLimit" title={customerEfficiencyModal.data.email}>
                                    {customerEfficiencyModal.data.email !== "" ? customerEfficiencyModal.data.email : "-"}
                                </h4>
                                <h6 className="text-orca mt-3 mb-0">
                                    {t('phone_2', { ns: 'common'})}
                                </h6>
                                <h4>
                                    {customerEfficiencyModal.data.phone !== "" ? customerEfficiencyModal.data.phone : "-"}
                                </h4>
                                <h6 className="text-orca mt-3 mb-0">
                                    {t('selected_tag_list')}
                                </h6>
                                <h4
                                    className="oneLineLimit lh-md"
                                    title={customerEfficiencyModal.data.tag_name}
                                >
                                    {customerEfficiencyModal.data.tag_name !== "" ? customerEfficiencyModal.data.tag_name : "-"}
                                </h4>
                            </div>
                        </Col>

                        <Col xs={8}>
                            <EffModal 
                                settings={{
                                    ...customerEfficiencyModal,
                                    data: customerEfficiencyModal.data.customer_efficiency,
                                    pieData: smsPieData,
                                    labels: {
                                        title: 'label_title_sms_overview',
                                        keys: {
                                            sms_total_campaigns: { label: 'key_label_campaign_count', type: 'number' },
                                            sms_total_costs: { label: 'key_label_total_cost', type: 'number' },
                                            sms_success_rate: { label: 'key_label_campaign_success_rate', type: 'rate' },
                                            sms_failed_rate: { label: 'key_label_campaign_failed_rate', type: 'rate' },
                                        }
                                    },
                                    pieLabels: {
                                        title: 'label_title_campaign_success_rate',
                                        keys: {
                                            sms_success_rate: 'pie_key_campaign_success_rate',
                                            sms_failed_rate: 'pie_key_campaign_failed_rate',
                                        }
                                    },
                                    colors: ['#e85151', '#f3a5a5'],
                                }}
                            />

                            <hr />

                            <EffModal
                                settings={{
                                    ...customerEfficiencyModal,
                                    data: customerEfficiencyModal.data.customer_efficiency,
                                    pieData: mailPieDataOpens,
                                    labels: {
                                        title: 'label_title_email_overview',
                                        keys: {
                                            mail_total_campaigns: { label: 'key_label_campaign_count', type: 'number' },
                                            mail_open_rate: { label: 'key_label_campaign_open_rate', type: 'rate' },
                                            mail_click_rate: { label: 'key_label_campaign_click_rate', type: 'rate' },
                                        }
                                    },
                                    pieLabels: {
                                        title: 'label_title_campaign_avg_open_rate',
                                        keys: {
                                            sms_success_rate: 'pie_key_campaign_open_rate',
                                            sms_failed_rate: 'pie_key_campaign_unopen_rate',
                                        }
                                    },
                                    colors: ['#e85151', '#f3a5a5'],
                                }}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row className="pt-4 px-4">
                        <Col xs={6}>
                            <h5>{t('customer_report_sms_campaign_title')}</h5>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "name",
                                        "dataType": "text",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "status",
                                        "dataType": "text-center",
                                        "fieldName": "status"
                                    },
                                    {
                                        "head": "receive_time",
                                        "dataType": "datetime",
                                        "fieldName": "received_time"
                                    },
                                    {
                                        "head": "cost",
                                        "dataType": "text-end",
                                        "fieldName": "cost"
                                    },
                                ],
                                "data": customerEfficiencyModal.data.customer_efficiency_sms,
                                "size": 10,
                                "pagination": true,
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="pt-4 px-4">
                        <Col xs={6}>
                            <h5>{t('customer_report_email_campaign_open_title')}</h5>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "name",
                                        "dataType": "text",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "open_time",
                                        "dataType": "datetime",
                                        "fieldName": "received_time"
                                    },
                                ],
                                "data": customerEfficiencyModal.data.customer_efficiency_mail_open,
                                "size": 10,
                                "pagination": true,
                            }}
                            />
                        </Col>

                        <Col xs={6}>
                            <h5>{t('customer_report_email_campaign_receive_title')}</h5>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "name",
                                        "dataType": "text",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "receive_time",
                                        "dataType": "datetime",
                                        "fieldName": "received_time"
                                    },
                                ],
                                "data": customerEfficiencyModal.data.customer_efficiency_mail_sent,
                                "size": 10,
                                "pagination": true,
                            }}
                            />
                        </Col>
                    </Row>

                    <Row className="pt-4">
                        <Col xs={6}>
                            <h5>{t('customer_report_email_campaign_click_title')}</h5>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "name",
                                        "dataType": "text",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "click_time",
                                        "dataType": "datetime",
                                        "fieldName": "received_time"
                                    },
                                    {
                                        "head": "click_link",
                                        "dataType": "text",
                                        "fieldName": "link_clicked"
                                    },
                                ],
                                "data": customerEfficiencyModal.data.customer_efficiency_mail_click,
                                "size": 10,
                                "pagination": true,
                            }}
                            />
                        </Col>

                        <Col xs={6}>
                            <h5>{t('customer_report_email_campaign_bounce_title')}</h5>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "name",
                                        "dataType": "text",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "bounce_type",
                                        "dataType": "text",
                                        "fieldName": "bounce_type"
                                    },
                                    {
                                        "head": "receive_time",
                                        "dataType": "datetime",
                                        "fieldName": "received_time"
                                    },
                                ],
                                "data": customerEfficiencyModal.data.customer_efficiency_mail_bounce,
                                "size": 10,
                                "pagination": true,
                            }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('close', { ns: 'common' })}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ImportOptionsModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.data.message_type + "-" + modal.data.id,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "optionsModalShow": modal.optionsModalShow,
                "onBlacklistClick": handleModalShow,
                "onNormalClick": handleModalShow,
                "onCloseClick": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
        </div>
        <ModalApiStatus
            settings={{
            content: {
                status: apiStatus.status,
                message: apiStatus.message,
            },
            apiStatusModalShow: apiStatus.apiStatusModalShow,
            handleModalClose,
            }}
        />
        </>
    )
}